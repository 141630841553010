import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import ContentContext from '../contexts/Content';
import PageHeading from '../common/PageHeading';

const Bar = styled.div`
  background: ${({ theme }) => theme.dustyGrey};
  height: 1px;
  margin-bottom: 1rem;
  margin-left: 30%;
  width: 40%;
`;

const Email = styled.a`
  color: ${({ theme }) => theme.indianKhaki};
  text-decoration: none;
`;

const Grid = styled.div`
  column-gap: 10vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
`;

const Image = styled.div`
  background-color: ${({ theme }) => theme.mineShaft};
  background-image: url('${({ url }) => url}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2rem;
  padding-bottom: 130%;
`;

const Item = styled.div`
  text-align: center;
`;

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.mineShaft};
  text-decoration: none;
`;

const Name = styled.h2`
  font-family: 'PT Serif';
  font-weight: 400;
  margin: 0;
  margin-bottom: .75rem;
`;

const Poste = styled.h3`
  color: ${({ theme }) => theme.scorpion};
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding: 5rem 15vw;
  @media (max-width: 992px) { padding: 2rem 15vw }
`;

const Team = () => {
  const { collaborateurs } = useContext(ContentContext);

  return (
    <Wrapper>
      <PageHeading>Notre équipe</PageHeading>
      <Grid>
        {collaborateurs.map((collaborateur, index) => (
          <Item
            data-aos={index % 2 === 0 ? 'fadeRight' : 'fadeLeft'}
            key={collaborateur.sys.id}
          >
            <Link to={`/equipe/${collaborateur.fields.url}`}>
              <Image url={collaborateur.fields.photo.fields.file.url} />
              <Name>{collaborateur.fields.nomComplet}</Name>
              <Poste>{collaborateur.fields.poste}</Poste>
            </Link>
            <Bar />
            <Email href={`mailto:${collaborateur.fields.email}`}>
              {collaborateur.fields.email}
            </Email>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default Team;
