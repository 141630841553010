import './config/redirect';

import 'normalize.css/normalize.css';
import './static/styles/base.scss';
import 'aos/dist/aos.css';

import AOS from 'aos';
import React from 'react';
import ReactDOM from 'react-dom';

import setAppHeight from './utils/setAppHeight';

import App from './components/App';

setAppHeight();

AOS.init({ delay: 300, duration: 600, once: true });

ReactDOM.render(<App />, document.getElementById('app'));
