import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import BarsSVG from '../static/icons/bars.svg';

const Button = styled.button`
  background: none;
  border: none;
  height: 1rem;
  padding: 0;
  &:focus { outline: none; }
`;

const Icon = styled(SVG)`
  color: ${({ theme }) => theme.mineShaft};
  height: 100%;
`;

const Logo = styled.img`
  height: 2.5rem;
`;

const Wrapper = styled.div`
  align-items: center;
  background: white;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;
  padding: 0 2rem;
  height: 5rem;
`;

const NavigationMobile = ({ showMobileMenu }) => (
  <Wrapper>
    <NavLink to="/">
      <Logo alt="Binsard Martine Associés" src="https://images.ctfassets.net/9pnagkik38jb/4xd0a02Irk4Ogr1NIhMrWo/1d1e52c375a817a73f92267f0b7ab235/logo.png" />
    </NavLink>
    <Button onClick={showMobileMenu}>
      <Icon src={BarsSVG} />
    </Button>
  </Wrapper>
);

NavigationMobile.propTypes = {
  showMobileMenu: PropTypes.func.isRequired,
};

export default NavigationMobile;
