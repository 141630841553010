import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import client from '../config/contentful';
import theme from '../config/theme';
import useWidth from '../hooks/useWidth';

import ContentContext from '../contexts/Content';
import Helmet from './Helmet';
import MobileMenu from './MobileMenu';
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import Router from './Router';

const Clickable = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  transform: translateX(${({ translate }) => (translate ? '-65vw' : '0')});
  transition: transform .3s;
  @media (max-width: 992px) { flex-direction: column; }
`;

const App = () => {
  const [content, setContent] = useState(null);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const windowWidth = useWidth();

  useEffect(() => {
    const promises = [
      client.getEntries({
        content_type: 'article',
        limit: 14,
        order: '-fields.date',
      }),
      client.getEntries({
        content_type: 'collaborateur',
        order: 'fields.ordre',
      }),
      client.getEntries({
        content_type: 'competence',
      }),
    ];
    Promise.all(promises).then(([
      { items: articles },
      { items: collaborateurs },
      { items: competences },
    ]) => {
      setContent({
        allArticlesLoaded: articles.length < 14,
        articles,
        collaborateurs,
        competences,
      });
    });
  }, []);

  const loadNextArticles = useCallback(() => {
    client.getEntries({
      content_type: 'article',
      limit: 8,
      order: '-fields.date',
      skip: content.articles.length,
    }).then(({ items: nextArticles }) => {
      setContent({
        ...content,
        allArticlesLoaded: nextArticles.length < 8,
        articles: [...content.articles, ...nextArticles],
      });
    });
  }, [content]);

  const hideMobileMenu = () => setMobileMenuVisible(false);

  const showMobileMenu = () => setMobileMenuVisible(true);

  if (!content) { return null; }

  return (
    <ContentContext.Provider value={content}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Helmet />
          <Wrapper translate={mobileMenuVisible}>
            {windowWidth <= 992
              ? <NavigationMobile showMobileMenu={showMobileMenu} />
              : <NavigationDesktop />}
            <Router
              hideMobileMenu={hideMobileMenu}
              loadNextArticles={loadNextArticles}
            />
            {mobileMenuVisible && <Clickable onClick={hideMobileMenu} />}
            {windowWidth <= 992 && <MobileMenu visible={mobileMenuVisible} />}
          </Wrapper>
        </BrowserRouter>
      </ThemeProvider>
    </ContentContext.Provider>
  );
};

export default App;
