import React, { useContext } from 'react';
import { Helmet as HelmetExtraneous } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import getFromPath from '../utils/getFromPath';
import { pagesDescriptions, pagesTitles } from '../config/seo';

import ContentContext from '../contexts/Content';

const Helmet = () => {
  const content = useContext(ContentContext);

  const path = useLocation().pathname;

  return (
    <HelmetExtraneous>
      <title>{getFromPath(pagesTitles, path, content)}</title>
      <meta
        name="description"
        content={getFromPath(pagesDescriptions, path, content)}
      />
      <link rel="canonical" href={`https://binsard-martine.com${path}`} />
    </HelmetExtraneous>
  );
};

export default Helmet;
