import React from 'react';
import styled from 'styled-components';

const CloudsImageURL = 'https://images.ctfassets.net/9pnagkik38jb/6QbWtQ7K0fJf65OG2kA8JH/e0fc1a08f9433eeea591b3b257408352/clouds.jpg';

const Paragraph = styled.p`
  animation-delay: .4s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  font-size: 1.2rem;
  line-height: 2;
  opacity: 0;
  transform: translate(-3rem, 0);
  @media (max-width: 992px) {
    font-size: 1rem;
    transform: translate(0, 3rem);
  }
`;

const TextContainer = styled.div`
  color: white;
  margin-left: 15%;
  margin-top: 25%;
  text-align: right;
  width: 65%;
  @media (max-width: 992px) {
    margin: 0;
    text-align: center;
    width: 80%;
  }
`;

const Title = styled.h1`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  font-family: 'PT Serif';
  font-size: 3rem;
  line-height: 1.3;
  opacity: 0;
  transform: translate(-3rem, 0);
  @media (max-width: 992px) {
    font-size: 2rem;
    transform: translate(0, 3rem);
  }
`;

const Wrapper = styled.div`
  align-items: center;
  background-image: url('${CloudsImageURL}');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
`;

const Home = () => (
  <Wrapper>
    <TextContainer>
      <Title>
        Défense pénale & contentieux
      </Title>
      <Paragraph>
        Cabinet indépendant dédié à la défense pénale et au contentieux,
        assistant une clientèle française et internationale de personnes
        physiques et morales devant l’ensemble des juridictions pénales,
        civiles et commerciales.
      </Paragraph>
    </TextContainer>
  </Wrapper>
);

export default Home;
