import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import ContentContext from '../contexts/Content';
import PageHeading from '../common/PageHeading';

const ArticleTitle = styled.h2`
  display: -webkit-box;
  font-family: 'PT Serif';
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  @media (max-width: 992px) { font-size: 1.125rem; }
`;

const Bar = styled.div`
  background: ${({ theme }) => theme.dustyGrey};
  height: 1px;
  margin-bottom: 1rem;
  width: 100%;
`;

const Card = styled.div`
  border-left: .5rem solid ${({ theme }) => theme.indianKhaki};
  box-shadow: 2px 2px 8px 4px rgba(236, 236, 236, 0.5);
  padding-bottom: 140%;
  position: relative;
  @media (max-width: 992px) { padding-bottom: 150%; }
`;

const CardInner = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 2rem;
  @media (max-width: 992px) { padding: 1.5rem; }
`;

const Description = styled.p`
  display: -webkit-box;
  margin: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  @media (max-width: 992px) {
    font-size: .8rem;
    margin-bottom: 1.5rem;
  }
`;

const Grid = styled.div`
  column-gap: 7vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
`;

const Image = styled.div`
  background-image: url('${({ url }) => url}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
`;

const Link = styled.a`
  background: ${({ theme }) => theme.indianKhaki};
  color: white;
  display: inline-block;
  padding: 0.5rem 2.5rem;
  text-decoration: none;
`;

const Wrapper = styled.div`
  padding: 5rem 12vw;
  @media (max-width: 992px) { padding: 2rem 3rem 3rem 3rem }
`;

const News = ({ loadNextArticles }) => {
  const wrapperRef = useRef();

  const { allArticlesLoaded, articles } = useContext(ContentContext);

  useEffect(() => {
    if (!allArticlesLoaded) {
      const routerWrapper = document.getElementById('router-wrapper');
      const newsWrapperHeight = wrapperRef.current.offsetHeight;
      const scrollThreshold = newsWrapperHeight - 2 * window.innerHeight;

      const handleScroll = () => {
        const scrollBottom = routerWrapper.scrollTop + window.innerHeight;
        if (scrollBottom > scrollThreshold) {
          routerWrapper.removeEventListener('scroll', handleScroll);
          loadNextArticles();
        }
      };

      routerWrapper.addEventListener('scroll', handleScroll);

      return () => {
        routerWrapper.removeEventListener('scroll', handleScroll);
      };
    }

    return () => {};
  }, [allArticlesLoaded, articles]);

  return (
    <Wrapper ref={wrapperRef}>
      <PageHeading>Nos actualités</PageHeading>
      <Grid>
        {articles.map((article) => (
          <Card key={article.sys.id}>
            <CardInner>
              <Image url={article.fields.image.fields.file.url} />
              <ContentContainer>
                <ArticleTitle>{article.fields.titre}</ArticleTitle>
                <Bar />
                <Description>{article.fields.description}</Description>
                <Link
                  href={article.fields.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  En lire plus
                </Link>
              </ContentContainer>
            </CardInner>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

News.propTypes = {
  loadNextArticles: PropTypes.func.isRequired,
};

export default News;
