import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export const pagesTitles = {
  '^/$': () => 'Binsard Martine Associés, défense pénale & contentieux',
  '^/actualites/?$': () => 'Nos actualités - Binsard Martine Associés',
  '^/competences/?$': () => 'Nos compétences - Binsard Martine Associés',
  '^/contact/?$': () => 'Contactez-nous - Binsard Martine Associés',
  '^/equipe/?$': () => 'Notre équipe - Binsard Martine Associés',
  '^/equipe/[a-z]([a-z]|-)*/?$': (path, content) => {
    const url = path.split('/').slice(-1)[0];
    const collaborateur = content.collaborateurs.find((item) => (
      item.fields.url === url
    ));
    return collaborateur
      ? `${collaborateur.fields.nomComplet} - Binsard Martine Associés`
      : 'Page non trouvée - Binsard Martine Associés';
  },
  '^/mentions-legales/?$': () => 'Mentions légales - Binsard Martine Associés',
  '^.*$': () => 'Page non trouvée - Binsard Martine Associés',
};

export const pagesDescriptions = {
  '^/$': () => 'Cabinet indépendant dédié à la défense pénale et au contentieux, assistant une clientèle française et internationale de personnes physiques et morales devant l’ensemble des juridictions pénales, civiles et commerciales.',
  '^/actualites/?$': () => 'Retrouvez ici nos dernières actualités, articles et publications de Me Robin Binsard et Me Guillaume Martine.',
  '^/competences/?$': () => 'Cabinet spécialisé en droit pénal général, des affaires et international, ainsi qu’en contentieux civil et commercial.',
  '^/contact/?$': () => 'Retrouvez les coordonnées de notre cabinet. Nous sommes disponibles et à votre écoute 24/7.',
  '^/equipe/?$': () => 'Retrouvez ici la liste des membres de notre équipe.',
  '^/equipe/[a-z]([a-z]|-)*/?$': (path, content) => {
    const url = path.split('/').slice(-1)[0];
    const collaborateur = content.collaborateurs.find((item) => (
      item.fields.url === url
    ));
    return collaborateur
      ? documentToPlainTextString(collaborateur.fields.description.content[0])
      : 'Quoi que vous cherchiez, cela n\'existe plus. Les pages web sont fugitives, hélas ! comme les années.';
  },
  '^/mentions-legales/?$': () => 'Mentions légales du cabinet Binsard Martine Associés',
  '^.*$': () => 'Quoi que vous cherchiez, cela n\'existe plus. Les pages web sont fugitives, hélas ! comme les années.',
};
