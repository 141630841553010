import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import LinkedinSVG from '../static/icons/linkedin.svg';

const Icon = styled(SVG)`
  color: ${({ theme }) => theme.mineShaft};
  height: 1.5rem;
`;

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.mineShaft};
  display: block;
  text-decoration: none;
  transition: color 0.3s;
  &:not(:last-child) { margin-bottom: 2rem; }
  &.active { color: ${({ theme }) => theme.indianKhaki}; }
`;

const LinksContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 100%;
`;

const LogoLink = styled(NavLink)`
  width: 50%;
`;

const Wrapper = styled.div`
  align-items: center;
  box-shadow: 2px 2px 8px 4px rgba(236, 236, 236, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20%;
  z-index: 5;
  ${({ hasBoxShadow }) => !hasBoxShadow && 'box-shadow: unset'}
`;

const NavigationDesktop = () => {
  const path = useLocation().pathname;

  return (
    <Wrapper hasBoxShadow={path !== '/'}>
      <LogoLink to="/">
        <Logo alt="Binsard Martine Associés" src="https://images.ctfassets.net/9pnagkik38jb/4xd0a02Irk4Ogr1NIhMrWo/1d1e52c375a817a73f92267f0b7ab235/logo.png" />
      </LogoLink>
      <LinksContainer>
        <Link exact to="/">ACCUEIL</Link>
        <Link exact to="/competences">COMPÉTENCES</Link>
        <Link to="/equipe">ÉQUIPE</Link>
        <Link exact to="/actualites">ACTUALITÉS</Link>
        <Link exact to="/contact">CONTACT</Link>
      </LinksContainer>
      <LinksContainer>
        <Link exact to="/mentions-legales">MENTIONS LÉGALES</Link>
        <a
          aria-label="linkedin"
          href="https://www.linkedin.com/company/binsard-cabinet-d-avocats/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon src={LinkedinSVG} />
        </a>
      </LinksContainer>
    </Wrapper>
  );
};

export default NavigationDesktop;
