import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import ContentContext from '../contexts/Content';

const Bar = styled.div`
  background: ${({ theme }) => theme.dustyGrey};
  height: 1px;
  margin-bottom: 1.5rem;
  width: 40%;
  @media (max-width: 992px) { margin-left: 30% }
`;

const Description = styled.div`
  line-height: 1.4;
  text-align: justify;
  a {
    color: ${({ theme }) => theme.indianKhaki};
    text-decoration: none;
  }
  p:last-child { margin-bottom: 0; }
`;

const Email = styled.a`
  color: ${({ theme }) => theme.indianKhaki};
  display: block;
  margin-bottom: 2rem;
  text-decoration: none;
`;

const Image = styled.img`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  background-color: ${({ theme }) => theme.mineShaft};
  opacity: 0;
  transform: translateX(-3rem);
  width: 100%;
  @media (max-width: 992px) { transform: translate(0); }
`;

const Name = styled.h1`
  font-family: 'PT Serif';
  font-weight: 400;
  margin: 0;
  margin-bottom: .75rem;
`;

const Poste = styled.h2`
  color: ${({ theme }) => theme.scorpion};
  font-family: 'PT Serif';
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
`;

const RightItem = styled.div`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  opacity: 0;
  transform: translateX(3rem);
  @media (max-width: 992px) { transform: translate(0); }
`;

const Wrapper = styled.div`
  align-items: start;
  column-gap: 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 5rem 7vw;
  row-gap: 2rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    padding: 2rem 3rem 3rem 3rem;
    text-align: center;
  }
`;

const TeamMember = () => {
  const { collaborateurs } = useContext(ContentContext);

  const { id } = useParams();

  const collaborateur = collaborateurs.find((item) => item.fields.url === id);

  return (
    <Wrapper>
      <Image
        alt={collaborateur.fields.nomComplet}
        src={collaborateur.fields.photo.fields.file.url}
      />
      <RightItem>
        <Name>{collaborateur.fields.nomComplet}</Name>
        <Poste>{collaborateur.fields.poste}</Poste>
        <Bar />
        <Email href={`mailto:${collaborateur.fields.email}`}>
          {collaborateur.fields.email}
        </Email>
        <Description
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(collaborateur.fields.description),
          }}
        />
      </RightItem>
    </Wrapper>
  );
};

export default TeamMember;
