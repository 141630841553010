import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import ContentContext from '../contexts/Content';

const Description = styled.div`
  line-height: 1.4;
  &:not(:last-child) { margin-bottom: 2rem; }
`;

const DescriptionJustify = styled(Description)`
  text-align: justify;
`;

const ItemContainer = styled.div`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  &:not(:last-child) { margin-bottom: 4rem; }
  @media (max-width: 992px) { flex-direction: column; }
`;

const Left = styled.div`
  width: calc(65% - 4rem);
  @media (max-width: 992px) {
    margin-bottom: 2rem;
    width: 100%;
  }
`;

const Right = styled.div`
  color: white;
  background: rgba(187,166,144, 0.7);
  padding: 2rem 1rem;
  width: 35%;
  @media (max-width: 992px) { width: 100% }
`;

const RightHead = styled.h3`
  font-family: 'PT Serif';
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.indianKhaki};
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  background: none;
  border: 1px solid ${({ isActive, theme }) => (isActive ? theme.mineShaft : theme.dustyGrey)};
  color: ${({ isActive, theme }) => (isActive ? theme.mineShaft : theme.dustyGrey)};
  cursor: pointer;
  padding: 1rem;
  transition: all 0.3s;
  &:focus { outline: none; }
`;

const TabsGrid = styled.div`
  animation-delay: .6s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 4rem;
  opacity: 0;
  row-gap: 1rem;
  transform: translateY(-3rem);
  @media (max-width: 992px) {
    animation-delay: .3s;
    grid-template-columns: 1fr;
    transform: translate(0);
  }
`;

const Title = styled.h2`
  font-family: 'PT Serif';
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  padding: 5rem 8vw;
  @media (max-width: 992px) { padding: 2rem 3rem 3rem 3rem }
`;

const Skills = () => {
  const { competences } = useContext(ContentContext);

  const tabNames = competences.reduce((acc, competence) => {
    if (!acc.includes(competence.fields.section)) {
      return acc.concat(competence.fields.section);
    }
    return acc;
  }, []);

  const [activeTab, setActiveTab] = useState(tabNames[0]);

  const activeItems = competences.filter((competence) => (
    competence.fields.section === activeTab
  ));

  return (
    <Wrapper>
      <TabsGrid>
        {tabNames.map((tab) => (
          <Tab
            isActive={activeTab === tab}
            key={tab}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </Tab>
        ))}
      </TabsGrid>
      {activeItems.map((item) => (
        <ItemContainer key={item.sys.id}>
          <Left>
            <Title>{item.fields.titre}</Title>
            <DescriptionJustify
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(item.fields.description),
              }}
            />
            <Subtitle>Dossiers récents</Subtitle>
            <DescriptionJustify
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(item.fields.dossiersRecents),
              }}
            />
          </Left>
          <Right>
            <RightHead>EXPERTISE</RightHead>
            <Description
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(item.fields.expertise),
              }}
            />
          </Right>
        </ItemContainer>
      ))}
    </Wrapper>
  );
};

export default Skills;
