import React from 'react';
import styled from 'styled-components';

const Text = styled.h2`
  left: 0;
  margin: 0;
  padding: 0 8vw;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 992px) { padding: 0 3rem }
`;

const NoMatch = () => <Text>404 - Cette page n&apos;existe pas</Text>;

export default NoMatch;
