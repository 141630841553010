import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  background: ${({ theme }) => theme.indianKhaki};
  height: 3px;
  width: 5rem;
`;

const Title = styled.h1`
  font-family: 'PT Serif';
  font-weight: 400;
  margin-top: 0;
  text-align: center;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  @media (max-width: 992px) { margin-bottom: 4rem; }
`;

const PageHeading = ({ children }) => (
  <Wrapper>
    <Title>{children}</Title>
    <Bar />
  </Wrapper>
);

PageHeading.propTypes = { children: PropTypes.string.isRequired };

export default PageHeading;
