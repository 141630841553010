import AOS from 'aos';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Contact from './Contact';
import Home from './Home';
import LegalNotice from './LegalNotice';
import News from './News';
import NoMatch from './NoMatch';
import Skills from './Skills';
import Team from './Team';
import TeamMember from './TeamMember';

const Wrapper = styled.div`
  background: white;
  background-image: ${({ path }) => (path === '/competences' ? 'url(\'https://images.ctfassets.net/9pnagkik38jb/1vQM3nLOWyGRO3pTeUtZ9w/01011bb44c00805d61c7cf92150bb016/bg.png\')' : 'unset')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  position: relative;
  width: 80%;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 992px) {
    height: calc(100% - 5rem);
    width: 100%;
  };
`;

const Router = ({ hideMobileMenu, loadNextArticles }) => {
  const wrapperRef = useRef();

  const path = useLocation().pathname;

  useEffect(hideMobileMenu, [path]);

  useLayoutEffect(() => {
    wrapperRef.current.scrollTop = 0;
  }, [path]);

  return (
    <Wrapper
      id="router-wrapper"
      onScroll={() => AOS.refresh()}
      path={path}
      ref={wrapperRef}
    >
      <Switch>
        <Route path="/contact" component={Contact} exact />
        <Route path="/" component={Home} exact />
        <Route path="/mentions-legales" component={LegalNotice} exact />
        <Route
          path="/actualites"
          component={() => <News loadNextArticles={loadNextArticles} />}
          exact
        />
        <Route path="/competences" component={Skills} exact />
        <Route path="/equipe" component={Team} exact />
        <Route path="/equipe/:id" component={TeamMember} exact />
        <Route component={NoMatch} exact />
      </Switch>
    </Wrapper>
  );
};

Router.propTypes = {
  hideMobileMenu: PropTypes.func.isRequired,
  loadNextArticles: PropTypes.func.isRequired,
};

export default Router;
