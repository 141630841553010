import React from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: ${({ theme }) => theme.indianKhaki};
  text-decoration: none;
`;

const Wrapper = styled.div`
  padding: 5rem 6vw;
  text-align: justify;
  h1 {
    text-align: center;
    margin: 0;
    margin-bottom: 3rem;
  }
  p {
    line-height: 1.5;
    &:last-child { margin-bottom: 0; }
  }
  @media (max-width: 992px) {
    padding: 2rem;
    h1 {
      text-align: left;
      margin-bottom: 2rem;
    }
  }
`;

const LegalNotice = () => (
  <Wrapper>
    <h1>Mentions légales</h1>
    <h2>Publication</h2>
    <p>
      Le présent site web, accessible à l&apos;adresse binsard-martine.com est édité
      par l&apos;AARPI BINSARD MARTINE ASSOCIES, dont le Cabinet est sis 215 rue du
      Faubourg Saint Honoré, 75008 Paris.
    </p>
    <p>
      Adresse électronique :&nbsp;
      <Link href="mailto:rb@binsard-martine.com">rb@binsard-martine.com</Link>
    </p>
    <p>
      L&apos;AARPI BINSARD MARTINE ASSOCIES est inscrite au barreau de Paris, et est
      ainsi rattachée à l&apos;Ordre du barreau de Paris (France).
    </p>
    <p>
      L&apos;AARPI BINSARD MARTINE ASSOCIES est soumise au règlement intérieur du
      barreau de Paris et au règlement intérieur national dans leur version
      applicable ainsi qu&apos;aux dispositions législatives et règlementaires
      relatives à la profession d&apos;avocat.
    </p>
    <p>Le directeur de la publication du Site Web est Maître Robin BINSARD.</p>
    <h2>Conception, réalisation et hébergement du site</h2>
    <p>Le présent site a été conçu par l&apos;agence digitale <Link href="https://blackjelly.co/">Black Jelly</Link>. Pour tout projet, contactez-nous sur : <Link href="mailto:hello@blackjelly.co">hello@blackjelly.co</Link></p>
    <h2>Droits de propriété intellectuelle</h2>
    <p>
      Le site Web est protégé par les droits de propriété intellectuelle et est
      la propriété exclusive du cabinet BINSARD MARTINE ASSOCIES. La conception,
      le graphisme, le contenu, l&apos;organisation de ce site sont des œuvres
      originales et sont l&apos;entière propriété du cabinet BINSARD MARTINE ASSOCIES.
      Toute reproduction, ou démarquage, total ou partiel, fait sans le
      consentement de l&apos;auteur, ou de ses ayants droit, ou de ses ayants cause
      est illicite. Il en est de même pour la traduction, l&apos;adaptation,
      l&apos;arrangement par quelque procédé que ce soit (Loi 57298 du 11 mars 1957).
    </p>
    <h2>Liens hypertextes avec des sites tiers</h2>
    <p>
      BINSARD MARTINE ASSOCIES n&apos;exerce aucun contrôle sur le contenu des sites
      tiers. L&apos;existence d&apos;un lien hypertexte entre BINSARD MARTINE ASSOCIES et
      un site tiers ne signifie en aucune manière le contrôle du contenu du site
      tiers par BINSARD MARTINE ASSOCIES.
    </p>
    <h2>Exonération de responsabilité</h2>
    <p>
      Il appartient à l&apos;utilisateur de ce site de prendre toutes les mesures
      appropriées de façon à protéger ses propres données et/ou logiciels de
      contamination par d&apos;éventuels virus circulant sur le réseau Internet.
    </p>
    <h2>Modification du site</h2>
    <p>
      BINSARD MARTINE ASSOCIES se réserve le droit de modifier ou de corriger le
      contenu de ce site et cette mention légale à tout moment et ceci sans préavis.
    </p>
    <h2>Données personnelles</h2>
    <p>
      Aucune donnée personnelle n&apos;est collectée par ce site internet via les
      cookies sans votre consentement. Une fois votre consentement donné, il est
      valide au maximum 13 mois.
    </p>
    <p>
      Les fichiers logs du serveur web collectent votre adresse IP à des fins
      d&apos;étude en cas de piratage ou d&apos;abus et sont conservées quelques semaines.
    </p>
    <p>
      Les données des formulaires de ce site ne sont pas enregistrées sans votre
      consentement, et peuvent être transmises par email au gestionnaire du site.
      Une suppression ou une pseudonymisation est réalisée automatiquement après
      13 mois. Les fichiers logs du serveur de mail collectent votre email et
      votre adresse IP à des fins d&apos;étude en cas de piratage ou d&apos;abus et sont
      conservées quelques semaines.
    </p>
    <p>
      Les données des formulaires transmises par email peuvent être archivées
      par le gestionnaire du site sur l&apos;un ou plusieurs de ses ordinateurs. Pour
      en demander la suppression, contactez le gestionnaire du site.
    </p>
  </Wrapper>
);

export default LegalNotice;
