import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(NavLink)`
  color: white;
  display: block;
  text-decoration: none;
  &:not(:last-child) { margin-bottom: 2rem; }
  &.active { color: ${({ theme }) => theme.indianKhaki}; }
`;

const Wrapper = styled.div`
  align-items: flex-end;
  background: ${({ theme }) => theme.mineShaft};
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  position: absolute;
  right: -65vw;
  top: 0;
  width: 65vw;
`;

const MobileMenu = ({ visible }) => (
  <Wrapper visible={visible}>
    <Link exact to="/">ACCUEIL</Link>
    <Link exact to="/competences">COMPÉTENCES</Link>
    <Link to="/equipe">ÉQUIPE</Link>
    <Link exact to="/actualites">ACTUALITÉS</Link>
    <Link exact to="/contact">CONTACT</Link>
    <Link exact to="/mentions-legales">MENTIONS LÉGALES</Link>
  </Wrapper>
);

MobileMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default MobileMenu;
