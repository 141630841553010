import GoogleMapReact from 'google-map-react';
import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import MapMarkerSVG from '../static/icons/map-marker.svg';
import PageHeading from '../common/PageHeading';

const Grid = styled.div`
  column-gap: 8vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
`;

const MapMarker = styled(SVG)`
  color: ${({ theme }) => theme.mineShaft};
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
`;

const Label = styled.div`
  color: ${({ theme }) => theme.indianKhaki};
  font-family: 'PT Serif';
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const Left = styled.div`
  text-align: center;
`;

const LeftAnimate = styled.div`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  opacity: 0;
  transform: translateX(-3rem);
  @media (max-width: 992px) { transform: translate(0); }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.scorpion};
  display: block;
  text-decoration: none;
  &:not(:last-child) { margin-bottom: 3rem; }
  @media (max-width: 992px) {
    &:not(:last-child) { margin-bottom: 2rem; }
  }
`;

const Right = styled.div`
  animation-delay: .3s;
  animation-duration: .6s;
  animation-fill-mode: forwards;
  animation-name: fade;
  background-color: ${({ theme }) => theme.mineShaft};
  height: 70vh;
  opacity: 0;
  transform: translateX(3rem);
  @media (max-width: 992px) {
    height: 50vh;
    transform: translate(0);
  }
`;

const Value = styled.div`
  color: ${({ theme }) => theme.scorpion};
  &:not(:last-child) { margin-bottom: 3rem; }
  @media (max-width: 992px) {
    &:not(:last-child) { margin-bottom: 2rem; }
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 100%;
  padding: 5rem 12vw;
  @media (max-width: 992px) { padding: 2rem 3rem 3rem 3rem }
`;

const Contact = () => (
  <Wrapper>
    <Grid>
      <Left>
        <PageHeading>Contact</PageHeading>
        <LeftAnimate>
          <Label>Adresse</Label>
          <Value>11, quai Anatole France | 75007 Paris</Value>
          <Label>Téléphone</Label>
          <Link href="tel:+33184252666">+33 (0) 1 84 25 26 66</Link>
          <Label>Email</Label>
          <Link href="mailto:contact@binsard-martine.com">
            contact@binsard-martine.com
          </Link>
        </LeftAnimate>
      </Left>
      <Right>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAKWoMekl5NjU8I3j1Nrd_DxJsGgcQhJcY' }}
          defaultCenter={{ lat: 48.861190, lng: 2.323630 }}
          defaultZoom={14}
        >
          <MapMarker
            lat={48.861190}
            lng={2.323630}
            src={MapMarkerSVG}
          />
        </GoogleMapReact>
      </Right>
    </Grid>
  </Wrapper>
);

export default Contact;
